.page.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  h1 {
    text-align: center;
    color: $primary;
  }
  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2em;
    padding: 10px 20px;
    outline: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: 100ms ease all;
    &:hover {
      color: $secondary-dark;
      outline: 1px solid $secondary-dark;
    }
  }
}
