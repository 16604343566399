@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.3.0/uicons-solid-straight/css/uicons-solid-straight.css');

@import "./vars/vars";
@import "./globals/globals";
@import "./functions/functions";
@import "loaders";

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Hanken Grotesk", sans-serif !important;
}

* {
  a {
    color: $secondary-dark;
    font-size: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul {
    margin: 0;
    padding: 0;
  }
}

:root {
  margin: 0;
  padding: 0;
}
body {
  // Basically removing the body element, and making the .container replace it
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.container {
  // Acts as a replacement for the <body> element
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: absolute;
}

@import "./header/header";

@import "./errors/error_page.scss";

@import "../pages/connector.scss";
