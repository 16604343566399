.page.home {
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  margin-top: $header-height;
  .wrapper {
    width: 100%;
    z-index: 2;
    .section {
      &.head {
        width: 100%;
        height: calc(100vh - $header-height);
        display: grid;
        display: grid;
        grid-template-columns: 1fr 0.2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding: 20px;
        .title-wrapper {
          height: 100%;
          padding-left: 10%;
          grid-area: 1 / 1 / 2 / 2;
          .title {
            margin-top: 10%;
            font-size: 76px;
          }
          .text {
            font-size: 16px;
          }
        }
        .main-actions {
          height: 100%;
          grid-area: 1 / 2 / 2 / 3;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          position: relative;
          .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            list-style: none;
            width: 90px;
            height: auto;
            max-height: 100%;
            padding: 20px;
            border-radius: 10px;
            background: $primary-dark;
            gap: 10px;
            z-index: +1;
            position: relative;
            .action {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;
              position: relative;
              .action-link {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 300%;
                width: 50px;
                height: 50px;
                padding: 0;
                margin: 0;
                text-decoration: none;
                position: relative;
                &:hover {
                  color: darken($secondary, 10%);
                  .tooltip {
                    transform: translateY(-50%);
                  }
                }
                i.fi {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .tooltip {
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 18px;
                  width: auto;
                  height: auto;
                  right: calc(100% + 10px);
                  top: 50%;
                  padding: 10px 20px;
                  border-radius: 10px 0 0 10px;
                  transform: translateY(-50%) translateX(100%) scale(0);
                  word-wrap: none;
                  text-wrap: nowrap;
                  background: $primary-dark;
                  position: absolute;
                  transition: 100ms ease all;
                  z-index: -1;
                }
              }
            }
          }
        }
        .center-down-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100px;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%) rotate(90deg);
          position: absolute;
          z-index: 99;
          transition: 100ms ease all;
          &:hover {
            transform: translate(-50%, -10px) rotate(90deg);
            i.fi {
              color: $secondary;
            }
          }
          i.fi {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 300%;
            position: relative;
          }
        }
        .background-image {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
          pointer-events: none;
          .image {
            width: 100%;
            min-width: 100vw;
            min-height: 100vh;
            height: 100%;
            object-fit: cover;
            filter: saturate(0.4) blur(2px);
            source {
              width: 100%;
              height: 100%;
            }
          }
          .filter {
            width: 110%;
            height: 110%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgb(2, 0, 36);
            background: -moz-linear-gradient(
              90deg,
              rgba(2, 0, 36, 1) 18%,
              rgba(0, 212, 255, 0) 100%
            );
            background: -webkit-linear-gradient(
              90deg,
              rgba(2, 0, 36, 1) 18%,
              rgba(0, 212, 255, 0) 100%
            );
            background: linear-gradient(
              90deg,
              rgba(2, 0, 36, 1) 18%,
              rgba(0, 212, 255, 0) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
            z-index: +1;
            position: absolute;
          }
        }
      }
    }
  }
}
