nav {
  * {
    margin: 0;
    padding: 0;
  }
  a {
    $color: white;
    color: $color;
    @include interaction-transition;
    &:hover {
      color: darken($color, 10%);
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: $header-height;
    left: 0;
    top: 0;
    padding: 0;
    background: $primary-dark;
    z-index: +99;
    position: fixed;
    ul {
      padding: 0;
      margin: 0;
    }
    a {
      text-decoration: none;
    }
    .logo {
      margin: 10px 20px;
      .logo-text {
        padding: 0;
        font-weight: 900;
        font-size: 3em;
      }
    }
    .nav-toggle {
      display: none;
    }
    .nav-links {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      list-style: none;
      gap: 10px;
      position: relative;
      .Link.wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .nav-link {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          font-size: 1.4em;
          padding: 10px 20px;
        }

        .dropdown {
          display: none;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 20px;
          background: $primary-darker;
          z-index: 99;
          padding: 30px 20px;
          position: absolute;
          top: 100%;
          min-width: 200px;
          left: translateX(-100%);
          list-style: none;
          &.open {
            display: flex;
          }
          li {
            a {
              font-size: 16px;
              padding: 10px 20px;
              min-width: fit-content;
            }
          }
        }
      }
    }
    .nav-links-mobile {
      display: none;
    }
    .right-wrapper {
      margin: 10px 20px;
      .actions {
        .action {
          color: black;
          padding: 10px 20px;
          background: $secondary-dark;
          &:hover {
            background: darken(
              $secondary-dark,
              $hover-darken-effect-percentage
            );
          }
        }
      }
    }
  }
}
